
import {
  defineComponent,
  nextTick,
  reactive,
  ref,
  useStore,
  useContext,
  watch,
} from '@nuxtjs/composition-api';

import useForm from '~/composables/useForm';
import useCountries from '~/composables/useCountries';
import useModalDialog from '~/composables/useModalDialog';

interface ConsultationForm {
  site: string;
  page_url?: string;
  lang: string;
  name: string;
  email: string;
  phone: string;
  country: string;
  message: string;
  personal_data: boolean;
  marketing: boolean;
}
const FORM_DEFAULTS = {
  name: '',
  email: '',
  phone: '',
  country: '',
  message: '',
  personal_data: false,
  marketing: false,
};

const stringifyBoolean = (v: any) =>
  typeof v !== 'boolean' ? v : (v && 'yes') || 'no';

export default defineComponent({
  components: {},
  setup() {
    const store = useStore();
    const config = store.getters['config/getConfig'];
    const lang: string = store.getters['config/getActiveLanguage'];
    const form = ref();
    const { formSuccess, formPending, submit, recaptcha } = useForm();
    const { $gtm, nuxtState } = useContext();
    const {
      isOpen: infoModalIsOpen,
      open: openInfoModal,
      close: closeInfoModal,
    } = useModalDialog('info');

    const formData = reactive<ConsultationForm>({
      site: config.domain,
      lang,
      ...FORM_DEFAULTS,
    });

    const clearForm = () => {
      Object.assign(formData, {
        site: config.domain,
        lang,
        ...FORM_DEFAULTS,
      });

      nextTick(() => form.value.reset());
    };
    const formStartEventSent = ref(false);
    const { getCountriesList } = useCountries();
    const countries = getCountriesList();

    const submitForm = async () => {
      try {
        const payload = Object.fromEntries(
          Object.entries({
            ...formData,
            page_url: window.location.href,
          }).map(([k, v]) => [k, stringifyBoolean(v)]),
        );

        await submit(payload, nuxtState.config.consultFormUuid);
        $gtm.push({
          event: 'leadform_sent',
        });
        openInfoModal();
        clearForm();
      } catch (error) {
        console.error('Error submitting form', error);
      }
    };

    const pushFormStartEvent = () => {
      if (!formStartEventSent.value) {
        $gtm.push({
          event: 'form_start',
        });
      }
      formStartEventSent.value = true;
    };

    watch(formData, () => {
      pushFormStartEvent();
    });

    return {
      form,
      formData,
      countries,
      formSuccess,
      formPending,
      submitForm,
      recaptcha,
      infoModalIsOpen,
      closeInfoModal,
    };
  },
});
